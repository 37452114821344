<template lang="pug">
.ds-pane
  .ds-pane-title(:class="{ 'has-border': headerHasBorder }")
    slot(name="title")
      sidebar-title
        span {{ $t(title) }}
  .ds-pane-content.pl-3.pr-3(@scroll="handleScroll")
    slot
    slot(name="advanced" v-if="showAdvanced || $slots.advancedContent")
      accordion(:data-pane-name="paneName" data-block-name="Advanced")
        template(#title)
          .font-weight-bold {{ $t('sidebarSettings.tabs.name.advanced') }}
        slot(name="advancedContent")
        slot(name="advancedContentEnding")
          slot(name="zIndex" v-if="$slots.zIndex || zIndex")
            pane-layout(layout="col")
              template(#left)
                .ds-label Z-index
              template(#right)
                om-input#zIndex(small v-model="zIndexValue")
          slot(name="savedStyle" v-if="$slots.savedStyle || savedStyle")
            pane-layout(gap="4px")
              template(#left)
                .ds-label {{ $t('customTheme.savedStyle') }}
              template(#right)
                custom-theme-style(v-if="isThemeKit" dsAllowed)
          slot(name="customClass" v-if="$slots.customClass || customClass")
            om-input#customClass(
              :label="$t('customClass')"
              :helpText="$t('addCustomClassText')"
              small
              :placeholder="$t('myCustomClass')"
              v-model="customClassValue"
            )
          slot(name="elementId")
            pane-layout(gap="4px")
              template(#left)
                .ds-label {{ $t('elementId') }}
              template(#right)
                .d-flex.align-items-center
                  om-input#elementId.w-100(small :value="elementId" readOnly)
                  om-button.ml-2.copyElement(
                    ghost
                    small
                    iconOnly
                    icon="copy-alt"
                    v-clipboard:copy="elementId"
                    v-clipboard:success="onCopy"
                  )
    slot(name="afterAdvanced")
    slot(name="elementDefaults")
      SetElementDefaults
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import CustomThemeStyle from '../../components/CustomThemeStyle.vue';

  export default {
    components: {
      Accordion,
      PaneLayout,
      CustomThemeStyle,
    },
    mixins: [itemMixin],
    props: {
      savedStyle: {
        type: Boolean,
        default: true,
      },
      zIndex: {
        type: Boolean,
        default: true,
      },
      customClass: {
        type: Boolean,
        default: true,
      },
      showAdvanced: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      paneName: null,
      headerHasBorder: false,
    }),
    computed: {
      ...mapState(['selectedElement']),
      ...mapGetters(['isThemeKit']),
      title() {
        return this.selectedElement.type.replace('Om', 'edit');
      },
      elementId() {
        return this.selectedElement.uid;
      },
      zIndexValue: {
        get() {
          return this.getValueOf('zIndex');
        },
        set(v) {
          this.setValueOf('zIndex', v === '' ? null : v);
        },
      },
      customClassValue: {
        get() {
          return this.getValueOf('data.customClasses');
        },
        set(v) {
          this.setValueOf('data.customClasses', v);
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.paneName = this.$el.closest('.ds-pane').querySelector('.ds-pane-title').textContent;
      });
    },
    methods: {
      handleScroll(event) {
        this.headerHasBorder = event.target.scrollTop > 0;
      },
      onCopy() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.elementIdCopied'),
        });
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .ds-pane
    height: 100%
    padding-top: .5rem
    &-title
      &.has-border
        border-bottom: 1px solid $om-gray-300
    &-content
      height: calc(100vh - 100px)
      overflow-y: scroll
      overflow-x: hidden
      padding-bottom: 90px
      color: #23262A
      scrollbar-color: $om-gray-400 transparent
      &::-webkit-scrollbar-track
        -webkit-box-shadow: none
        box-shadow: none
        border-radius: 10px
        background: transparent
      &::-webkit-scrollbar
        height: 8px
        background-color: transparent
        margin-bottom: 10px
      &::-webkit-scrollbar-thumb
        background: $om-gray-400
      &::-webkit-scrollbar-button
        display: none
      &::-webkit-scrollbar-button:vertical:start:decrement
        display: none
      &::-webkit-scrollbar-button:vertical:end:increment
          display: none
    .label
      color: $om-gray-700
      font-size: 0.75rem
      line-height: 1.5rem
      font-weight: 400
    .ds-tooltip
      .popper
        max-width: 250px
    .ds-label
      color: $om-gray-700
    label
      &.input-label[for="customClass"],
      &.select-label[for="savedStyle"]
        padding: 0
    hr
      margin-block: 5px
</style>
